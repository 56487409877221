<template>
  <el-container>
    <el-header>
      <Navbar></Navbar>
    </el-header>
    <el-main>
      <el-card class="box-card">
        <div>
          <h2>Patient Portal</h2>
          <p>If you are a COPD patient, please use the button below to sign in. </p>
          <p>New to our app? Use the button below to register.</p>
          <Login patient="0"></Login>
        </div>
        <el-button  @click="bypassLogin"> Bypass Login (demo purposes) </el-button>
      </el-card>
      <el-card class="box-card">
        <h2>Healthcare Provider Portal</h2>
        <p>Healthcare providers, please use the button below to sign in.</p>
        <p>If you are new to our app, you will need your license number ready to register.</p>
        <Login patient="1"></Login>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import Navbar from "@/components/NavBar.vue";
export default {
  mounted() {
    this.disclaimer();
  },
  name: "Home",
  components: {
    Login,
    Navbar,
  },
  methods: {
    disclaimer() {
      alert("Thank you visiting! This is a demo build version and may not function properly.")
    },
    bypassLogin() {
      this.$cookies.set("isHealthCare", false);
      this.$cookies.set("isPatient", true);
      this.$store.commit('setSessionID', "123");
      this.$cookies.set("sessionID", this.$store.getters.getSessionID);
      this.$cookies.set("firstName", "Demo");
      this.$cookies.set("lastName", "User");
      this.$cookies.set("DOB", "10/15/2021");
      this.$cookies.set("phoneNumber", "1234567890");
      this.$cookies.set("email", "this@demo.com");
      this.$router.push("patients");
    }
  }
};
</script>

<style>
.box-card {
    width: 480px;
    margin: 0 auto;
    margin-top: 50px;

  }
</style>
